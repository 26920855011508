import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_4/sub_12/slide4';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    leftColumn: mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "slide-4-12-4"}}) {
        body
      },
      hoverDot: mdx(
        frontmatter: {
          language: { eq: "FR" }
          title: { eq: "squadron-dots" }
        }
      ) {
        exports {
          hoverInfo {
            positionLeft
            positionTop
            identifier
          }
        }
      },
      modal41241:  mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "squadron-modals"}, selector: {eq: "modal41241"}}) {
        body
      },
      modal41242:  mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "squadron-modals"}, selector: {eq: "modal41242"}}) {
        body
      },
      modal41243:  mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "squadron-modals"}, selector: {eq: "modal41243"}}) {
        body
      },
      modal41244:  mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "squadron-modals"}, selector: {eq: "modal41244"}}) {
        body
      },
      modal41245:  mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "squadron-modals"}, selector: {eq: "modal41245"}}) {
        body
      },
      modal41246:  mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "squadron-modals"}, selector: {eq: "modal41246"}}) {
        body
      },
      audio: mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "slide-4-12-4-audio"}}) {
        excerpt(pruneLength: 100000)
      },
  }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
