import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_4/sub_12/slide4';

const Slide = () => {
  const query = useStaticQuery(graphql`
    query {
      mdx(
        frontmatter: { language: { eq: "FR" }, title: { eq: "slide-4-12-4" } }
      ) {
        body
      }
      file(relativePath: { eq: "chapter_4/sub_12/mobile/4-12-4-bg.jpg" }) {
        childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      audioImage: file(
        relativePath: { eq: "chapter_4/sub_12/4-12-4-audio.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      hoverDot: mdx(
        frontmatter: { language: { eq: "FR" }, title: { eq: "squadron-dots-mobile" } }
      ) {
        exports {
          hoverInfo {
            insideText
            identifier
          }
        }
      }
      modal41241: mdx(
        frontmatter: {
          language: { eq: "FR" }
          title: { eq: "squadron-modals" }
          selector: { eq: "modal41241" }
        }
      ) {
        body
      }
      modal41242: mdx(
        frontmatter: {
          language: { eq: "FR" }
          title: { eq: "squadron-modals" }
          selector: { eq: "modal41242" }
        }
      ) {
        body
      }
      modal41243: mdx(
        frontmatter: {
          language: { eq: "FR" }
          title: { eq: "squadron-modals" }
          selector: { eq: "modal41243" }
        }
      ) {
        body
      }
      modal41244: mdx(
        frontmatter: {
          language: { eq: "FR" }
          title: { eq: "squadron-modals" }
          selector: { eq: "modal41244" }
        }
      ) {
        body
      }
      modal41245: mdx(
        frontmatter: {
          language: { eq: "FR" }
          title: { eq: "squadron-modals" }
          selector: { eq: "modal41245" }
        }
      ) {
        body
      }
      modal41246: mdx(
        frontmatter: {
          language: { eq: "FR" }
          title: { eq: "squadron-modals" }
          selector: { eq: "modal41246" }
        }
      ) {
        body
      }
      caption: mdx(
        frontmatter: { language: { eq: "FR" }, title: { eq: "slide-4-6-1" } }
      ) {
        body
      }
    }
  `);
  return <Template query={query} />;
};

export default Slide;
